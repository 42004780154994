import { Pic } from './../../models/pic';
import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  currentState:number = 0;
  @Output() stateChanged: EventEmitter<number> =   new EventEmitter();
  
  images: Pic[] = [
  
    {index:0,
      url:"url(/assets/img/STINGSNAP7.jpg)",
      cssClass:"pic0",
      textClass:"name-text-left"
       
    },
    {index:1,
      url:"url(/assets/img/DRCameraSetup.jpg)",
      cssClass:"pic1",
      textClass:"name-text-right"
    },
    {index:2,
      url:"url(/assets/img/bar_close.png)",
      cssClass:"pic2",
      textClass:"name-text-right"
      
    },
    {index:3,
      url:"url(/assets/img/DR_monitorFloat.jpg)",
      cssClass:"pic3",
      textClass:"name-text-left"
      
    },
    {index:4,
      url:"url(/assets/img/floatDR.jpg)",
      cssClass:"pic4",
      textClass:"name-text-right"
   
    },

    {index:5,
      url:"url(/assets/img/MHHGA.png);",
      cssClass:"pic5",
      textClass:"name-text-left"
    }
  ]
  currentImage:Pic;
  pictureBanner:string = "picture-banner";
  fadeSlideRight: string = "fade-in-slide-right";
  fadeSlideLeft: string = "fade-in-slide-left"
  constructor( ) { }

  ngOnInit() {
    this.getImage()
}
ngOnChanges(): void {
  //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //Add '${implements OnChanges}' to the class.
  console.log("changes!")
}
getImage(){  
  let randomRoll = Math.floor(Math.random() *6)
  console.log(randomRoll + " THIS IS THE PICTURE ROLL")
 this.currentImage = this.images[randomRoll];
 console.log(this.currentImage)
 console.log("^^^^^^^^ this is our image ")
}
getUrl(){
  console.log(this.currentImage.url)
  console.log("^^^^^^^ this is passed as a style string")
  console.log(document.getElementById('picture').style)
}
changeState(num){
  console.log(num + " passed back to parent")
  this.stateChanged.emit(num)
}
}
