import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-this-iszme-showing-off',
  templateUrl: './this-iszme-showing-off.component.html',
  styleUrls: ['./this-iszme-showing-off.component.css']
})
export class ThisIszmeShowingOffComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
