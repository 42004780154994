import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workfromhome',
  templateUrl: './workfromhome.component.html',
  styleUrls: ['./workfromhome.component.css']
})
export class WorkfromhomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
