import { SiteState } from './models/SiteState';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @Input() currentState: number;
  title = 'timMckiernan';
  video: any;
  states: SiteState[] = [
    {
      index: 0,
      title: 'legit',
      styleString: "style=:"
    },
    {
      index: 1,
      title: 'audio',
      styleString: "style=:"
    },
    {
      index: 2,
      title: 'bio',
      styleString: "style=:"
    },
  ]
  displayState: number = this.states[0].index

  checkState() {
    console.log(this.displayState)
  }
  changeState(state) {
    this.displayState = this.states[state].index
    console.log("state changed:")
  }
  display() {
    console.log("working!!!!")
  }

}
