import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legit',
  templateUrl: './legit.component.html',
  styleUrls: ['./legit.component.css']
})
export class LegitComponent implements OnInit {
  video:any
  innerVideo:any
  reel:string = 'https://www.youtube.com/embed/MQIzuhdpujU'
  constructor() { }

  ngOnInit() {
    this.getVideo()
  }
  getVideo(){
    this.video = document.getElementById("video");
    this.innerVideo =  this.video.contentDocument || this.video.contentWindow.document;
  }
}
